import { FC, memo, PropsWithChildren } from "react";
import { useTheme } from "styled-components";
import { StyledAccessibleSvg } from "./styles";
import { ISmileShapeProps } from "./types";

const SmileShapeComponent: FC<PropsWithChildren<ISmileShapeProps>> = (props) => {
    const theme = useTheme();
    const { variant = "primary", width, solid, ...restProps } = props;
    return (
        <StyledAccessibleSvg
            viewBox="0 0 80 28"
            title="smile"
            {...restProps}
            boxWidth={width}
            boxHeight={width ? Math.round((width * 28) / 80) : null}
        >
            <path
                stroke={variant === "inherit" ? "currentColor" : theme.color.palette[variant]}
                strokeWidth={solid ? 0 : 2}
                vectorEffect="non-scaling-stroke"
                fill={solid ? (variant === "inherit" ? "currentColor" : theme.color.palette[variant]) : "none"}
                d="M39.985 27.86C18.457 27.86.745 12.78 0 12.138L10.45 0c.14.119 14.055 11.843 29.535 11.843C55.37 11.843 69.365.133 69.5.016l10.47 12.122c-.746.642-18.457 15.722-39.985 15.722"
            />
        </StyledAccessibleSvg>
    );
};

export const SmileShape = memo(SmileShapeComponent);
