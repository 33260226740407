import { AppInstance } from "@shared/utils/app-instance";
import { IPatronsDataProps, IPatronsList } from "@ui/Patrons/types";

export const PRESENTATION_MEETING_URL = AppInstance.isSlovak
    ? "https://calendly.com/susedia-sk/30min"
    : "https://meetings.hubspot.com/langova";

export const PATRONS_DATA: Record<IPatronsList, IPatronsDataProps> = {
    polach: {
        role: "Michal Polách, Předseda výboru SV Masarykova",
        img: "/static/images/patron-avatars/avatar-polach.png",
        text: (
            <>
                Portál SVJ Sousedé.cz přináší mnohem jednodušší přístup k&nbsp;informacím. Obrovskou výhodou je možnost
                rozšíření například o&nbsp;hlasování či instalace aplikace do mobilních telefonů.
            </>
        ),
    },
    patron2: {
        role: "Jednatel správní firmy, Tanvald",
        img: "/static/images/patron-avatars/businessman.jpg",
        text: (
            <>
                Díky možnostem, které nám dává řešení od sousedů, pohodlně spravujeme spoustu nemovitostí a&nbsp;přibyly
                nám i&nbsp;drobné firmy z&nbsp;našeho okolí, které ocenily nabídku na úklid kanceláří.
            </>
        ),
    },
    patron3: {
        role: "Kateřina Nováková, člen domu",
        img: "/static/images/patron-avatars/woman-user.jpg",
        text: (
            <>
                Je to opravdu vychytaný SVJ portál. Díky aplikaci jsem byla schopná nahlásit havárii prasklých stupaček
                a&nbsp;ihned upozornit i&nbsp;ostatní sousedy. Situaci jsme vyřešili na pár kliknutí a&nbsp;škody na
                majetku tak byly minimální.
            </>
        ),
    },
    patron4: {
        role: "Člen SVJ, Břeclav",
        img: "/static/images/patron-avatars/SVJmember.jpg",
        text: (
            <>
                Výdaje své bytové jednotky mám pod kontrolou a&nbsp;vše od výboru SVJ mi chodí na mobil. Je to nesmírně
                pohodlné a&nbsp;zvykli si na to i&nbsp;starší vlastníci.
            </>
        ),
    },
    horacek: {
        role: "Jaroslav Horáček - předseda SVJ Jiráskova 300",
        img: "/static/images/patron-avatars/avatar-horacek.png",
        text: (
            <>
                Je třeba jít s&nbsp;dobou a&nbsp;teď tu máme k&nbsp;dispozici uživatelsky přívětivé řešení, které nám
                výrazně pomáhá v&nbsp;komunikaci se spoluvlastníky a&nbsp;zpřístupňuje jim všechny dokumenty
                a&nbsp;kontakty důležité pro chod domu a&nbsp;činnost společenství.
            </>
        ),
    },
    patron6: {
        role: "OSVČ",
        img: "/static/images/patron-avatars/osvc.jpg",
        text: (
            <>
                Jsem instalatér a&nbsp;díky sousedům mohu nabízet své služby přímým zákazníkům on-line a&nbsp;vše řeším
                v&nbsp;jedné aplikaci, kde mám sám také agendu ke svému podnikání, což mi zjednodušuje činnost.
            </>
        ),
    },
    patron7: {
        role: "Majitel rodinného domu",
        img: "/static/images/patron-avatars/house-owner.jpg",
        text: (
            <>
                Hlášení závad nyní řešíme fakt snadno díky mobilní apce. Kdokoliv někde zjistí třeba prasklou žárovku,
                vyfotí a&nbsp;pošle mi to. Já tak vím hned, co se kde stalo a&nbsp;z&nbsp;mobilu zadám pokyn správci
                k&nbsp;výměně.
            </>
        ),
    },
    patron8: {
        role: "OSVČ",
        img: "/static/images/patron-avatars/osvc-chairman.jpg",
        text: (
            <>
                Hojně využívám informací z&nbsp;Wiki a&nbsp;došlo i&nbsp;na právní servis. I&nbsp;když moc zkušeností
                jako začínající předseda nemám, se sousedy se cítím sebejistě. Plná verze se nám zkrátka vyplatí.
            </>
        ),
    },
    patron9: {
        role: "Pracovník údržby",
        img: "/static/images/patron-avatars/installer.jpg",
        text: (
            <>
                Když mi přišla nahlášená závada na mobil, po opravě jsem začal využívat přímou komunikaci
                s&nbsp;předsedou a&nbsp;info o&nbsp;opravě jsem zavěsil družstvu na nástěnku. Naší firmě se tak
                o&nbsp;100&nbsp;% zlepšily vztahy s&nbsp;uživateli domu a&nbsp;výborem.
            </>
        ),
    },
    patron10: {
        role: "Dispečerka správní firmy",
        img: "/static/images/patron-avatars/operator.jpg",
        text: (
            <>
                Našim servisákům rozděluji úkoly a&nbsp;výjezdy do kalendáře u&nbsp;nemovitostí. Máme větší přehled,
                rychlejší zadávání a&nbsp;hotový servis zaměstnanec napíše na nástěnku konkrétnímu domu. Vše tak řešíme
                na jednom místě a&nbsp;hojně využíváme aplikaci v&nbsp;telefonu.
            </>
        ),
    },
    patron11: {
        role: "Manažer firmy",
        img: "/static/images/patron-avatars/manager.jpg",
        text: (
            <>
                Se sousedy jsme optimalizovali část pracovních činností zaměstnanců v&nbsp;terénu a&nbsp;administrativu
                zjednodušili. Velkou výhodou je také přístup odkudkoliv, kde je wifi. Neřešíme již pevné stanice
                s&nbsp;nainstalovaným software.
            </>
        ),
    },
    patron12: {
        role: <>Skupina venčení pejsků, Tř.&nbsp;Letců, Liberec</>,
        img: "/static/images/patron-avatars/dog-group.jpg",
        text: (
            <>
                Venčením to začalo…kromě toho teď taky chodíme s&nbsp;novými přáteli na pivo. Skvělá apka
                s&nbsp;intuitivním ovládáním.
            </>
        ),
    },
    patron13: {
        role: <>Uživatelka sousedů, 58&nbsp;let</>,
        img: "/static/images/patron-avatars/woman-user-older.jpg",
        text: (
            <>
                S&nbsp;bývalou kolegyní pořádáme kurzy šití pro lidi z&nbsp;ulice. Jen jsme umístily nabídku
                a&nbsp;vytvořily skupinu na sousedech. Teď znám více lidí z&nbsp;mé bezprostřední blízkosti a&nbsp;vždy
                si popovídáme u&nbsp;kávy.
            </>
        ),
    },
    patron14: {
        role: <>Maminka Eva, 24&nbsp;let</>,
        img: "/static/images/patron-avatars/mother.jpg",
        text: (
            <>
                Skupina Maminky s&nbsp;kočárky vznikla na pískovišti našeho sídliště. Spíše než o&nbsp;těch procházkách
                je to o&nbsp;vzájemné pomoci, sdílení zkušeností a&nbsp;podpoře. Sousedé jsou výbornou pomůckou.
            </>
        ),
    },
    patron15: {
        role: "Cechmistr, Cech MZS-ČR",
        img: "/static/images/patron-avatars/man-guild.jpg",
        text: (
            <>
                On-line hlasování na sousedech vyřešilo naši neschopnost setkávat se na valných hromadách
                v&nbsp;dostatečném, usnášeníschopném kvóru. Takto se hlasování zúčastní každý, ať je kdekoliv. Hlasujeme
                pružněji a&nbsp;věci se tak rychleji hýbou.
            </>
        ),
    },
    patron16: {
        role: "Provozovatel - Školka golfu pro děti, Praha",
        img: "/static/images/patron-avatars/man-golf.jpg",
        text: (
            <>
                Na sousedech máme přehledně všechny skupiny dětí podle trenérů, kteří jednoduše komunikují
                s&nbsp;rodiči, když je třeba, a&nbsp;naopak. Díky tomu nepotřebujeme drahý software pro správu
                a&nbsp;komunikaci. Ani WhatsApp nepokryl tak skvěle naše potřeby.
            </>
        ),
    },
    patron17: {
        role: "Trenér - Klub floorball - junioři",
        img: "/static/images/patron-avatars/coach.jpg",
        text: (
            <>
                Často se nám mění časy a&nbsp;místa tréninků, protože jsme závislí na městských prostorách. Apka od
                sousedů vyřešila komunikaci se svěřenci a&nbsp;jako sportovní kroužek zde vedeme i&nbsp;kroniku klubu
                a&nbsp;fotogalerii zápasů pro rodiče.
            </>
        ),
    },
    patron18: {
        role: "Uživatel sousedů, nadšený sportovec",
        img: "/static/images/patron-avatars/man-sport.jpg",
        text: (
            <>
                S klukama z&nbsp;domu jsme si založili skupinu, v&nbsp;níž se každý den jednoduše domlouváme na
                nejrůznější sportovní akce a tréninky. Díky společné motivaci se poslední dobou účastníme dokonce
                i&nbsp;závodů, kdy využíváme apku sousedů také pro plánování společné dopravy.
            </>
        ),
    },
    patron19: {
        role: "Žákyně základní školy",
        img: "/static/images/patron-avatars/girl-school.jpg",
        text: (
            <>
                Ve třídě používáme novou apku pro sdílení zadání úkolů a&nbsp;materiálů k&nbsp;učení. Velmi mi to ulehčí
                práci po absenci ve škole, kdy potřebuji doplnit zameškané hodiny.
            </>
        ),
    },
    patron20: {
        role: "Absolvent vysoké školy",
        img: "/static/images/patron-avatars/man-student.jpg",
        text: (
            <>
                Na portálu sousedů jsme vytvořili skupiny tříd ze základní i&nbsp;střední školy. Mezi sebou si zde
                připomínáme příhody a&nbsp;fotky ze studentských let a&nbsp;také plánujeme každoročně třídní srazy.
            </>
        ),
    },
    patron21: {
        role: "Předseda sportovního spolku",
        img: "/static/images/patron-avatars/man-org.jpg",
        text: (
            <>
                Naši členové využívají funkcí webové i&nbsp;mobilní apky a&nbsp;jako nový spolek nemusíme investovat do
                tvorby složitých webových stránek, vše podstatné mezi sebou vyřešíme na sousedech.
            </>
        ),
    },
    patron22: {
        role: "Karel Novák, Předseda",
        text: (
            <>
                Z&nbsp;pohledu předsedy byla administrativa jednodušší než při hlasování SVJ pomocí e-mailů. Především
                hlasování generuje přehledný dokument, který se dá jako doklad k hlasování uložit.
            </>
        ),
    },
    patron23: {
        role: "Pavel Najman, BD Radhošť",
        text: (
            <>
                Online hlasování SVJ jsme použili zatím jen jednou, ale ohlasy od sousedů byly velmi pozitivní
                a&nbsp;schváleno jsme měli během sedmi dnů.
            </>
        ),
    },
    patron24: {
        role: "Lucie Voláková, ICF ČR",
        text: (
            <>
                Služba nám velice usnadnila hlasování SVJ per rollam o&nbsp;klíčových otázkách organizace, jako jsou
                schvalování rozpočtu, změna stanov, volby atd. Nemuseli jsme odkládat rozhodnutí důležitých témat či
                řešit zasílání hlasovacích formulářů a&nbsp;podkladů poštou.
            </>
        ),
    },
    vlach: {
        role: "Michal Vlach - SBD PS Liberec",
        img: "/static/images/patron-avatars/avatar-vlach.png",
        text: (
            <>
                Díky elektronické nástěnce máme možnost sdělovat v&nbsp;krátkém čase všem členům důležité aktuality,
                jako je například termín odečtů vody nebo informace o&nbsp;způsobu distribuce vyúčtování. Od platformy
                si slibujeme zrychlení v&nbsp;komunikaci, úsporu času a&nbsp;v&nbsp;konečném důsledku i&nbsp;peněz.
            </>
        ),
    },
    hajek: {
        role: "Mgr. Filip Hájek – First s.r.o. ",
        img: "/static/images/patron-avatars/avatar-hajek.png",
        text: (
            <>
                Vynikající je hromadná komunikace s&nbsp;členy například ohledně závad. Šetří čas, protože nemusíme
                rozesílat e-maily nebo vybavovat dotazy ohledně náhledů do dokumentů individuálně.
            </>
        ),
    },
    svamberk: {
        role: "JUDr. Bohuslav Švamberk – Místopředseda Sdružení bytových družstev a SVJ ČR z.s.",
        img: "/static/images/patron-avatars/avatar-svamberk.png",
        text: (
            <>
                Vynikající komunikační nástroj jak pro vlastníky a&nbsp;členy družstev, tak i&nbsp;pro správce
                nemovitostí. Přehledná a&nbsp;dobře strukturovaná vnitřní agenda SVJ i BD.
            </>
        ),
    },
    man: {
        role: "Jindřich Hanuš",
        img: "/static/images/patron-avatars/man.png",
        text: (
            <>
                Díky online distribuci vyúčtování můžeme vyúčtování rozeslat všem, a&nbsp;to bez běhání na poštu. Skvělé
                je mít pak ihned potvrzení o&nbsp;přijetí. Online distribuce nám ušetří hodně času.
            </>
        ),
    },
};
